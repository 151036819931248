import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Bio from "../components/bio"

import "bulma/css/bulma.css"
import "@fortawesome/fontawesome-free/css/all.css"
import "../scss/main.scss"

class Website extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Home page" />
        <Bio />
      </Layout>
    )
  }
}

export default Website

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
