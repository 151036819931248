import React from "react"
import { StaticQuery, graphql } from "gatsby"
const ReactMarkdown = require("react-markdown")

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        const { html } = data.markdownRemark

        return (
          <div className="content full_post">
            <ReactMarkdown source={html} escapeHtml={false} />
          </div>
        )
      }}
    />
  )
}

const bioQuery = graphql`
  query BioQuery {
    markdownRemark(fields: { slug: { eq: "/" } }) {
      id
      html
    }
  }
`

export default Bio
